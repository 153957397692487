import Header from '~/components/header/header'
import LicenseManagement from '~/components/license-management/license-management'

export default function ProLicenses() {
  return (
    <>
      <Header />
      <LicenseManagement />
    </>
  )
}
