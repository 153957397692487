import { Component } from 'solid-js'
import styles from '~/components/license-management/license-management.module.scss'
import LicenseManager from '~/components/license-manager/license-manager'

const LicenseManagement: Component = () => {
  return (
    <div class={styles.licenseManagement}>
      <LicenseManager />
    </div>
  )
}

export default LicenseManagement
